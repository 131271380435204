import React from 'react'

interface Props {
    initial: string
}

interface IInputValue {
    value: string
    clearValue: () => void
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const useInputValue = (props?: Props): IInputValue => {
    const [value, setValue] = React.useState<string>(props?.initial ?? "")
    const clearValue = () => setValue("")
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue(e.target.value)
    return {
        value,
        clearValue,
        onChange
    }
}

export default useInputValue
