import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { DOCUMENTS_API_SERVICE_DOMAIN } from "lib"
import { IUploadXMLResponse } from "types"

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

const formDataConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
}

export const wsUploadXMLManuallyService = async (file: any) => {
  const formData = new FormData()
  formData.append("file", file)
  try {
    const result = await intance.post("/uploadxml", formData, formDataConfig)
    const data = result.data as {
      claveAcceso: string
    }
    return data.claveAcceso
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const wsUploadXMLMasiveService = async (files: File[]) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append("file", file) // Cambiar 'files' a 'file'
  })

  try {
    const result = await intance.post("/upload/xml", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    console.log("result.data", result.data)
    const data: IUploadXMLResponse = result.data
    return data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}
