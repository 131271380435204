import React from 'react'
import logo from 'assets/img/logo-bg-transparent.svg'
import documents from 'assets/img/documents.jpg'
import { Button, CircularProgress, InputAdornment, Paper, Slide, Snackbar, TextField, Typography } from '@material-ui/core'
import { FIELDS } from 'lib'
import { useValues } from 'hooks'
import { AuthContext } from 'context'
import { Alert } from '@material-ui/lab'
import { IMessageConfig } from 'types'

const Login = () => {
    const { values: credentials, updateValue: handleChange } = useValues()
    const { searchingUser, login } = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            await login(credentials)
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setMessageConfig({ open: true, message: error.message, severity: "error" })
        }
    }
    return (
        <div className="flex w-full h-full overflow-hidden secondary-gradient px-20 items-center">
            <div className="pr-16" style={{ maxWidth: 'calc(100% - 500px)' }}>
                <img src={logo} alt="Logo" width={400} />
                <Typography className="pt-10" style={{ fontStyle: 'italic', fontWeight: 800 }} color="primary" variant="h3">
                    {"Sistema de gestión de documentos".toUpperCase()}
                </Typography>
                <Typography style={{ fontStyle: 'italic', fontWeight: 800 }} color="primary" variant="h2">
                    {"DHL EXPRESS ECUADOR S.A.".toUpperCase()}
                </Typography>
                <img
                    src={documents}
                    alt="Logo"
                    width={500}
                    height={"100%"}
                    className="absolute top-0 right-0"
                    style={{
                        objectFit: 'fill',
                        opacity: 0.15
                    }}
                />
                <Slide unmountOnExit={true} in={!searchingUser}>
                    <Paper
                        elevation={12}
                        className="absolute right-0 flex glass-paper"
                        style={{ height: '80vh', width: 500, top: '10vh', right: 100 }}
                    >
                        <form onSubmit={handleSubmit} className="my-12 flex flex-col items-center w-full px-16 justify-evenly flex-grow">
                            <div>
                                <TextField
                                    label={FIELDS.email.name}
                                    fullWidth
                                    autoFocus
                                    classes={{ root: "auth-input" }}
                                    required
                                    value={credentials[FIELDS.email.key]}
                                    onChange={(e) => handleChange(FIELDS.email.key, e.target.value)}
                                />
                                <TextField
                                    label={FIELDS.password.name}
                                    fullWidth
                                    style={{ marginTop: 16 }}
                                    classes={{ root: "auth-input" }}
                                    type={showPassword ? "text" : "password"}
                                    required
                                    value={credentials[FIELDS.password.key]}
                                    onChange={(e) => handleChange(FIELDS.password.key, e.target.value)}
                                    InputProps={{
                                        endAdornment: <React.Fragment>
                                            {
                                                credentials[FIELDS.password.key] &&
                                                <InputAdornment position="end">
                                                    <Button onClick={() => setShowPassword(prev => !prev)} size="small" disableElevation disableRipple disableTouchRipple disableFocusRipple>
                                                        {"Mostrar"}
                                                    </Button>
                                                </InputAdornment>
                                            }
                                        </React.Fragment>
                                    }}
                                />
                            </div>
                            <div className="mt-16 flex flex-col w-2/4">
                                <Button disabled={loading} fullWidth type="submit" size="large" disableElevation variant="contained" color="primary">
                                    {loading ? <CircularProgress size={20} thickness={12} /> : "Validar"}
                                </Button>
                            </div>
                        </form>
                    </Paper>
                </Slide>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Login
