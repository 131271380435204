import React from 'react'
import { Button, Icon, IconButton, Menu, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core'
import { ConfirmDialog, TaxForm, Search, FormDialog, FixedLoadingIndicator, ServerErrorHandler, LabelDisplayedRows } from 'components'
import { useInputValue, useTableModel } from 'hooks'
import { NEW_PATH, RESOURCES_PATH, EDIT_PATH, SMALL_ROWS_PER_PAGINATION_OPTIONS, TAX_PATH, deleteTaxService, TAX_TABLE_COLUMNS, ROOT_TITLE, TAX_TITLE } from 'lib'
import { useHistory, useLocation } from 'react-router'
import { IMessageConfig, ITax } from 'types'
import { ManagementContext } from 'context'
import { Alert } from '@material-ui/lab'
import { Helmet } from 'react-helmet'

const Taxes = () => {

    const { taxes, taxTypes, refreshTaxes, refreshTaxTypes } = React.useContext(ManagementContext)
    const { value: query, clearValue: handleClear, onChange: handleChange } = useInputValue()
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const location = useLocation()
    const history = useHistory()
    const [selected, setSelected] = React.useState<any | undefined>(undefined)
    const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null)
    const [deleteId, setDeleteId] = React.useState<number>(-1)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [filter, setFilter] = React.useState<number | undefined | string>("all")
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const getModel = React.useCallback((): any[] => {
        const src = filter === "all" ? [...taxes] : [...taxes].filter(a => a.taxTypeId === filter)
        if (Boolean(query)) {
            return src.filter((s: ITax) => {
                return s.description.toUpperCase().includes(query.toUpperCase())
                    || s.taxTypeId.toString().toUpperCase().includes(query.toUpperCase())
            })
        }
        return src
    }, [taxes, query, filter])
    const handleEdit = () => {
        setActionsAnchorEl(null)
        history.push({
            state: {
                edit: true,
                values: selected
            },
            pathname: `${RESOURCES_PATH}${TAX_PATH}${EDIT_PATH}`
        })
    }
    const handleConfirmDelete = (id: number) => {
        setActionsAnchorEl(null)
        setDeleteId(id)
    }
    const handleDelete = async () => {
        try {
            setLoading(true)
            await deleteTaxService(deleteId)
            setMessageConfig({ open: true, message: "Impuesto eliminado!", severity: "success" })
            await refreshTaxes()
            setLoading(false)
            return true
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo eliminar el impuesto...!", severity: "error" })
            setLoading(false)
            return false
        }
    }
    const handleChangeFilter = (value: string) => {
        setFilter(isNaN(parseInt(value)) ? value : parseInt(value))
    }
    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setLoading(true)
                await refreshTaxes()
                await refreshTaxTypes()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [refreshTaxes, refreshTaxTypes])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${TAX_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Search
                        onChange={handleChange}
                        query={query}
                        onClear={handleClear}
                        placeholer="Buscar por código, descripción o codigo JBA..."
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        style={{ width: 140, marginLeft: 15 }}
                        classes={{ root: "filter-text-field" }}
                        select
                        label="Tipo"
                        value={filter}
                        onChange={(e) => handleChangeFilter(e.target.value)}
                    >
                        {
                            taxTypes.map(taxType => (
                                <MenuItem dense value={taxType.taxId}>{taxType.name}</MenuItem>
                            ))
                        }
                        <MenuItem dense value={"all"}>{"Todos"}</MenuItem>
                    </TextField>
                </div>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => history.push(`${RESOURCES_PATH}${TAX_PATH}${NEW_PATH}`)}
                >
                    <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                    {"Nuevo"}
                </Button>
                <FormDialog
                    open={Boolean(location.pathname === `${RESOURCES_PATH}${TAX_PATH}${NEW_PATH}` || location.pathname === `${RESOURCES_PATH}${TAX_PATH}${EDIT_PATH}`)}
                    onClose={() => history.push(`${RESOURCES_PATH}${TAX_PATH}`)}
                >
                    <TaxForm
                        onSuccess={() => { setMessageConfig({ open: true, message: Boolean(selected) ? "Impuesto actualizado!" : " registrado!", severity: "success" }); refreshTaxes(); if (Boolean(selected)) { setSelected(undefined) } }}
                        onError={() => setMessageConfig({ open: true, message: "No se pude registrar el impuesto", severity: "error" })}
                        onClose={() => history.push(`${RESOURCES_PATH}${TAX_PATH}`)}
                    />
                </FormDialog>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {TAX_TABLE_COLUMNS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        padding={"default"}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as ITax[]).map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index.toString()}
                                        >
                                            <TableCell component="th" scope="row" padding="none">{row.taxCode}</TableCell>
                                            <TableCell padding="none" align="left">{row.description}</TableCell>
                                            <TableCell padding="none" align="left">{row.percentage.toFixed(2) + "%"}</TableCell>
                                            <TableCell padding="none" align="left">{row.jbaCode}</TableCell>
                                            <TableCell padding="none" align="left">{`${taxTypes.find(taxT => taxT.taxId === row.taxTypeId)?.name ?? ""} ${row.taxCode}`}</TableCell>
                                            <TableCell align="left">
                                                <IconButton onClick={(e) => { setActionsAnchorEl(e.currentTarget); setSelected(row) }} size="small">
                                                    <Icon fontSize="small">more_vert</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <Menu
                                    anchorEl={actionsAnchorEl}
                                    open={Boolean(actionsAnchorEl)}
                                    onClose={() => setActionsAnchorEl(null)}
                                >
                                    <MenuItem onClick={handleEdit} dense>{"Editar"}</MenuItem>
                                    <MenuItem onClick={() => handleConfirmDelete(selected?.taxId ?? -1)} dense>{"Eliminar"}</MenuItem>
                                </Menu>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={getModel().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Impuestos por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div>
            <ConfirmDialog
                onCancel={() => setDeleteId(-1)}
                onConfirm={handleDelete}
                open={Boolean(deleteId !== -1)}
                title={"¿Estás seguro que deseas eliminar?"}
                loading={loading}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading && Boolean(deleteId === -1)} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={refreshTaxes}
            />
        </Paper>
    )
}


export default Taxes
