import { Button, Divider, FormControlLabel, FormGroup, Icon, InputAdornment, Switch, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { CurrencyInput } from 'components'
import { useValues } from 'hooks'
import { FIELDS, formatCurrency, INITIAL_DETAIL } from 'lib'
import React from 'react'
import { IDetail, ITax, ITaxType } from 'types'

interface Props {
    onAdd: (detail: IDetail) => void
    onClose: () => void
    descriptionOnly?: boolean
    purchaseOrderDescriptionOnly?: boolean
    taxes: ITax[]
    loading?: boolean
    taxTypes: ITaxType[]
    edit?: IDetail
    rate12TaxOnly?: boolean
}

const DetailForm = (props: Props) => {
    const descriptionInput = React.useRef<any>(null)
    const codeInput = React.useRef<any>(null)
    const { taxTypes, loading, taxes, descriptionOnly, purchaseOrderDescriptionOnly, onAdd, onClose, edit, rate12TaxOnly } = props
    const [closeOnAdd, setCloseOnAdd] = React.useState<boolean>(false)
    const { updateValue: handleChange, values: detail, updateValues } = useValues(descriptionOnly ? edit ?? {} : INITIAL_DETAIL)
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onAdd({ ...detail, quantity: detail.quantity ?? 1, discount: detail.discount ?? 0 })
        updateValues({ ...(!descriptionOnly ? INITIAL_DETAIL : {}) })
        if (purchaseOrderDescriptionOnly) {
            codeInput.current?.focus()
        } else {
            descriptionInput.current?.focus()
        }
        if (closeOnAdd) { onClose() }
    }
    const getNoRetentionTaxes = React.useCallback((): ITax[] => {
        const noRetentionTaxTypes = taxTypes.filter(t => !t.forRetention).map(t => t.taxId)
        return rate12TaxOnly ? taxes.filter(t => (noRetentionTaxTypes.includes(t.taxTypeId) && t.percentage === 12 && t.taxCode.toString() === "2")) : taxes.filter(t => noRetentionTaxTypes.includes(t.taxTypeId))
    }, [taxes, taxTypes, rate12TaxOnly])
    React.useEffect(() => {
        if (edit) { updateValues(edit) }
    }, [edit, updateValues])

    React.useEffect(() => {
        if (rate12TaxOnly) {
            const noRetentionTaxTypes = taxTypes.filter(t => !t.forRetention).map(t => t.taxId)
            const value = taxes.find(t => (noRetentionTaxTypes.includes(t.taxTypeId) && t.percentage === 12 && t.taxCode.toString() === "2"))
            handleChange(FIELDS.tax.key, value)
        }
    }, [taxes, taxTypes, rate12TaxOnly, handleChange])

    return (
        <form onSubmit={handleSubmit} className="px-8 py-6 h-full flex flex-col">
            <div className="flex flex-col flex-grow">
                {
                    !purchaseOrderDescriptionOnly &&
                    <TextField
                        inputRef={descriptionInput}
                        variant="outlined"
                        multiline
                        fullWidth
                        size="small"
                        rows={2}
                        label={FIELDS.description.name}
                        required
                        value={detail[FIELDS.description.key] ? detail[FIELDS.description.key] : ""}
                        onChange={(e) => handleChange(FIELDS.description.key, e.target.value)}
                        onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
                        autoFocus
                    />
                }
                {
                    !descriptionOnly && !purchaseOrderDescriptionOnly &&
                    <React.Fragment>
                        <div className="w-full flex justify-between" style={{ marginTop: 15 }}>
                            <TextField
                                variant="outlined"
                                style={{ width: "58%" }}
                                size="small"
                                label={FIELDS.unitPrice.name}
                                onFocus={event => { event.target.select() }}
                                required
                                value={detail[FIELDS.unitPrice.key] ? detail[FIELDS.unitPrice.key] : "0"}
                                onChange={(e) => handleChange(FIELDS.unitPrice.key, e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon fontSize="small" color="action">attach_money</Icon>
                                        </InputAdornment>
                                    ),
                                    inputComponent: CurrencyInput as any,
                                }}
                            />
                            <TextField
                                variant="outlined"
                                style={{ width: "38%" }}
                                size="small"
                                label={FIELDS.quantity.name}
                                onFocus={event => { event.target.select() }}
                                onChange={(e) => handleChange(FIELDS.quantity.key, e.target.value.replace(".", "").replace("-", ""))}
                                value={detail[FIELDS.quantity.key] ? detail[FIELDS.quantity.key] : "1"}
                                required
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        </div>
                        <Autocomplete
                            options={getNoRetentionTaxes()}
                            loading={loading}
                            loadingText="Cargando..."
                            noOptionsText="No existen impuestos registrados"
                            getOptionLabel={(tax) => tax.description}
                            fullWidth
                            value={detail[FIELDS.tax.key] ? detail[FIELDS.tax.key] : null}
                            openOnFocus
                            size="small"
                            style={{ marginTop: 10 }}
                            onChange={(_, value) => handleChange(FIELDS.tax.key, value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    label={"Impuestos"}
                                />
                            )}
                        />
                        <div className="w-full flex justify-end" style={{ marginTop: 15 }}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                label={FIELDS.discount.name}
                                onFocus={event => { event.target.select() }}
                                value={detail[FIELDS.discount.key] ? detail[FIELDS.discount.key] : ""}
                                onChange={(e) => handleChange(FIELDS.discount.key, e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon fontSize="small" color="action">attach_money</Icon>
                                        </InputAdornment>
                                    ),
                                    inputComponent: CurrencyInput as any,
                                }}
                            />
                        </div>
                    </React.Fragment>
                }
                {
                    descriptionOnly &&
                    <div className="w-full flex justify-end flex-wrap" style={{ marginTop: 15 }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            label={FIELDS.subtotalNoTaxes.name}
                            onFocus={event => { event.target.select() }}
                            required
                            value={detail[FIELDS.subtotalNoTaxes.key] ? detail[FIELDS.subtotalNoTaxes.key] : ""}
                            onChange={(e) => handleChange(FIELDS.subtotalNoTaxes.key, e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">attach_money</Icon>
                                    </InputAdornment>
                                ),
                                inputComponent: CurrencyInput as any,
                            }}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 15 }}
                            size="small"
                            label={FIELDS.taxesValue.name}
                            onFocus={event => { event.target.select() }}
                            required
                            value={detail[FIELDS.taxesValue.key] ? detail[FIELDS.taxesValue.key] : ""}
                            onChange={(e) => handleChange(FIELDS.taxesValue.key, e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">attach_money</Icon>
                                    </InputAdornment>
                                ),
                                inputComponent: CurrencyInput as any,
                            }}
                        />
                    </div>
                }
                {
                    purchaseOrderDescriptionOnly &&
                    <div className="w-full flex justify-end flex-wrap" style={{ marginTop: 15 }}>
                        <TextField
                            inputRef={codeInput}
                            variant="outlined"
                            fullWidth
                            size="small"
                            label={FIELDS.purchaseCode.name}
                            onFocus={event => { event.target.select() }}
                            required
                            value={detail[FIELDS.purchaseCode.key] ? detail[FIELDS.purchaseCode.key] : ""}
                            onChange={(e) => handleChange(FIELDS.purchaseCode.key, e.target.value)}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            style={{ marginTop: 15 }}
                            rows={2}
                            label={FIELDS.description.name}
                            required
                            value={detail[FIELDS.description.key] ? detail[FIELDS.description.key] : ""}
                            onChange={(e) => handleChange(FIELDS.description.key, e.target.value)}

                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 15 }}
                            size="small"
                            label={FIELDS.quantity.name}
                            onFocus={event => { event.target.select() }}
                            onChange={(e) => handleChange(FIELDS.quantity.key, e.target.value.replace(".", "").replace("-", ""))}
                            value={detail[FIELDS.quantity.key] ? detail[FIELDS.quantity.key] : "1"}
                            required
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                        />

                    </div>
                }
                <div className="mt-10">
                    {
                        !descriptionOnly && !purchaseOrderDescriptionOnly &&
                        <React.Fragment>
                            <div className="flex items-center">
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} color="textSecondary" variant="caption">{FIELDS.subtotal.name}</Typography>
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{formatCurrency(detail[FIELDS.unitPrice.key] * detail[FIELDS.quantity.key])}</Typography>
                            </div>
                            <div className="flex items-center my-3">
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} color="textSecondary" variant="caption">{FIELDS.discount.name}</Typography>
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{`(-) ${formatCurrency(detail[FIELDS.discount.key] * 1)}`}</Typography>
                            </div>
                            <div className="flex items-center my-3">
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} color="textSecondary" variant="caption">{FIELDS.subtotalNoTaxes.name}</Typography>
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{formatCurrency((detail[FIELDS.unitPrice.key] * detail[FIELDS.quantity.key]) - (detail[FIELDS.discount.key] ?? 0))}</Typography>
                            </div>
                            <div className="flex items-center my-3 mb-5">
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} color="textSecondary" variant="caption">{FIELDS.taxesValue.name}</Typography>
                                <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{formatCurrency(((detail[FIELDS.unitPrice.key] * detail[FIELDS.quantity.key]) - (detail[FIELDS.discount.key] ?? 0)) * ((detail[FIELDS.tax.key] as ITax)?.percentage) / 100)}</Typography>
                            </div>
                        </React.Fragment>
                    }
                    <Divider />
                    {
                        !purchaseOrderDescriptionOnly &&
                        <div className="flex items-center mt-3">
                            <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} color="textSecondary" variant="button">{FIELDS.total.name}</Typography>
                            <Typography className="flex-1" style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="button" align="right">{descriptionOnly ? formatCurrency(parseFloat(detail[FIELDS.subtotalNoTaxes.key]) + parseFloat(detail[FIELDS.taxesValue.key])) : formatCurrency((((detail[FIELDS.unitPrice.key] * detail[FIELDS.quantity.key]) - (detail[FIELDS.discount.key] ?? 0)) * ((detail[FIELDS.tax.key] as ITax)?.percentage) / 100) + (detail[FIELDS.unitPrice.key] * detail[FIELDS.quantity.key]) - (detail[FIELDS.discount.key] ?? 0))}</Typography>

                        </div>
                    }
                </div>
            </div>
            <div className="w-full flex justify-end">
                {
                    !edit &&
                    <FormGroup className="p-2" row>
                        <FormControlLabel
                            control={<Switch color="primary" size="small" checked={closeOnAdd} onChange={(e) => setCloseOnAdd(e.target.checked)} />}
                            label={"Cerrar al agregar"}
                        />
                    </FormGroup>
                }
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit"
                >
                    {edit ? "Editar" : "Agregar"}
                </Button>
            </div>
        </form>
    )
}

export default DetailForm
