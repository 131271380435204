import React from 'react'
import { Order } from 'types'

export interface ITableModelHook {
    createSortHandler: (property: keyof any) => (event: React.MouseEvent<unknown>) => void
    rows: (model: any[]) => any[]
    orderBy: keyof any
    order: Order
    rowsPerPage: number
    page: number
    handleChangePage: (event: unknown, newPage: number) => void
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface Props {
    initialRowsPerPage?: number
}

const useTableModel = (props?: Props): ITableModelHook => {
    const [order, setOrder] = React.useState<Order>('asc')
    const [orderBy, setOrderBy] = React.useState<keyof any>('active')
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(props?.initialRowsPerPage ?? 25)
    const [page, setPage] = React.useState<number>(0)
    const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property)
    }
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }
    const getComparator = React.useCallback(function comparator<Key extends keyof any>(order: Order, orderBy: Key,): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
    }, [])
    
    const handleChangePage = React.useCallback((event: unknown, newPage: number) => {
        setPage(newPage)
    }, [])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const rows = React.useCallback((model: any[]) => {
        return stableSort(model, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }, [page, rowsPerPage, getComparator, order, orderBy])

    return {
        createSortHandler,
        handleChangePage,
        handleChangeRowsPerPage,
        order,
        orderBy,
        page,
        rows,
        rowsPerPage
    }
}

export default useTableModel
