import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { formatCurrency } from 'lib'
import { IReimbursementSummary } from 'types'

interface Props {
    reimbursementSummary: IReimbursementSummary
}

const ReimbursementValues = (props: Props) => {
    const { reimbursementSummary } = props
    const { nonTaxesSupport, taxSupport } = reimbursementSummary
    return (
        <Paper variant="outlined" className="mt-3" elevation={1}>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`Sustentos tributario`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Subtotal no objeto de impuestos"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(taxSupport?.subtotalNoTaxesObject ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Subtotal IVA 0%"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(taxSupport?.subtotalRateZero ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Subtotal IVA 12%"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(taxSupport?.subtotalRateTwelve ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"IVA"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(taxSupport?.iva ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Total"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(taxSupport?.total ?? 0)}`}</TableCell>
                        </TableRow>
                        <br />
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`Sustentos no tributario`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Subtotal no objeto de impuestos"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(nonTaxesSupport?.subtotalNoTaxesObject ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Subtotal IVA 0%"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(nonTaxesSupport?.subtotalRateZero ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Subtotal IVA 12%"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(nonTaxesSupport?.subtotalRateTwelve ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"IVA"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(nonTaxesSupport?.iva ?? 0)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{"Total"}</TableCell>
                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(nonTaxesSupport?.total ?? 0)}`}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    )
}

export default ReimbursementValues
