import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { TAX_PLAN_SUPPLIER_PATH, SUPPLIER_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getTaxPlanSupplierBySupplierIdService = async (id: any) => {
    try {
        const result = await intance.get(TAX_PLAN_SUPPLIER_PATH + SUPPLIER_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createTaxPlanSupplier = async (taxPlaSupplier: any) => {
    try {
        const result = await intance.post(TAX_PLAN_SUPPLIER_PATH, taxPlaSupplier)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteTaxPlanSupplier = async (id: any) => {
    try {
        const result = await intance.delete(TAX_PLAN_SUPPLIER_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}